import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
export const app = initializeApp({

  apiKey: 'AIzaSyAnDrB0KVeBrQf7W9-yblPF3QZuJtKlbjA',
  authDomain: 'vuejs-43dac.firebaseapp.com',
  databaseURL: 'https://vuejs-43dac.firebaseio.com',
  projectId: 'vuejs-43dac',
  storageBucket: 'vuejs-43dac.appspot.com',
  messagingSenderId: '1066621147438',
  appId: '1:1066621147438:web:9f219d6211038a739c02bf',
  measurementId: 'G-GG7MHXRMF9',
})

/* export const db = getDatabase(app);
export const dbRef =ref(getDatabase(app));
 */
export const dbRef = getFirestore(app);
export const analytics = getAnalytics(app);




/*Anaytics*/
export const loginGoogle = async () => {
  logEvent(analytics, 'login', {
    method: 'Google',
  })
}

export const loginEmail = async () => {
  logEvent(analytics, 'login', {
    method: 'email',
  })
}
export const loginEmailError = async () => {
  logEvent(analytics, 'error', {
    method: 'email',
  })
}


export const signupEmail = async () => {
  logEvent(analytics, 'sign_up', {
    method: 'email',
  })
}
export const signupEmailError = async () => {
  logEvent(analytics, 'sign_up', {
    method: 'email',
  })
}
export const signupGoogle = async () => {
  logEvent(analytics, 'sign_up', {
    method: 'google',
  })
}
export const dashboardClick = async () => {
  logEvent(analytics, 'dashboard_page', {
    method: 'click',
  })
}
export const forgotClick = async () => {
  logEvent(analytics, 'forgot_page', {
    method: 'click',
  })
}
export const planClick = async () => {
  logEvent(analytics, 'plan_page', {
    method: 'click',
  })
}

export const flashlightClick = async () => {
  logEvent(analytics, 'flashlight_page', {
    method: 'click',
  })
}

export const vibrationClick = async () => {
  logEvent(analytics, 'vibration_page', {
    method: 'click',
  })
}

export const brightnessClick = async () => {
  logEvent(analytics, 'brightness_page', {
    method: 'click',
  })
}

export const bluetoothClick = async () => {
  logEvent(analytics, 'bluetooth_page', {
    method: 'click',
  })
}

export const soundClick = async () => {
  logEvent(analytics, 'sound_page', {
    method: 'click',
  })
}

export const screenshotClick = async () => {
  logEvent(analytics, 'screenshot_page', {
    method: 'click',
  })
}

export const turboSearchClick = async () => {
  logEvent(analytics, 'turbo_search_page', {
    method: 'click',
  })
}

export const contactsClick = async () => {
  logEvent(analytics, 'contacts_page', {
    method: 'click',
  })
}

export const onCallClick = async () => {
  logEvent(analytics, 'on_call_page', {
    method: 'click',
  })
}

export const smsClick = async () => {
  logEvent(analytics, 'sms_page', {
    method: 'click',
  })
}
export const callLogClick = async () => {
  logEvent(analytics, 'call_log_page', {
    method: 'click',
  })
}
export const actionPageClick = async () => {
  logEvent(analytics, 'action_page', {
    method: 'click',
  })
}
export const instagramClick = async () => {
  logEvent(analytics, 'instagram_page', {
    method: 'click',
  })
}

export const installAppClick = async () => {
  logEvent(analytics, 'install_app_page', {
    method: 'click',
  })
}

export const locationClick = async () => {
  logEvent(analytics, 'location_page', {
    method: 'click',
  })
}

export const logoutClick = async () => {
  logEvent(analytics, 'logout_page', {
    method: 'click',
  })
}
