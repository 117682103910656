<template>
  <v-app>
    <v-main
      ><v-progress-linear indeterminate v-show="$store.state.loader"></v-progress-linear>
      <router-view></router-view>
      <v-dialog
      v-model="$store.state.loader"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
        </v-dialog>
    </v-main>
  </v-app>
</template>

<script>


export default {}
</script>

<style>
</style>
