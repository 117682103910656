var CryptoJS = require('crypto-js')

export const setUserData = (user) => {

    if (localStorage) {
        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), process.env.VUE_APP_CRYPTOJS).toString();
        localStorage.setItem(process.env.VUE_APP_LS_USERDATA, ciphertext);
    }

}
export const setPrices = (user) => {

    if (localStorage) {
        let ciphertext = CryptoJS.AES.encrypt(user, process.env.VUE_APP_CRYPTOJS).toString();
        localStorage.setItem(process.env.VUE_APP_LS_CURRENT_PRICES, ciphertext);
    }

}
export const getPrices = () => {
    if (localStorage) {
        let userData = localStorage.getItem(process.env.VUE_APP_LS_CURRENT_PRICES)
        if (userData) {
            let bytes = CryptoJS.AES.decrypt(userData, process.env.VUE_APP_CRYPTOJS)
            let originalText = bytes.toString(CryptoJS.enc.Utf8);
            return originalText;
        }
    }
}
export const setCurrentDeviceSelected = (deviceId) => {

    if (localStorage) {
        let device={
            "id":deviceId
        }
        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(device), process.env.VUE_APP_CRYPTOJS).toString();
        localStorage.setItem(process.env.VUE_APP_LS_CURRENT_DEVICE_SELECT, ciphertext);
    }

}
export const getCurrentDeviceSelected = () => {
    if (localStorage) {
        let userData = localStorage.getItem(process.env.VUE_APP_LS_CURRENT_DEVICE_SELECT)
        if (userData) {
            let bytes = CryptoJS.AES.decrypt(userData, process.env.VUE_APP_CRYPTOJS)
            let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return originalText.id;
        }
    }
}
export const getUserData = () => {
    if (localStorage) {
        let userData = localStorage.getItem(process.env.VUE_APP_LS_USERDATA)
        if (userData) {
            let bytes = CryptoJS.AES.decrypt(userData, process.env.VUE_APP_CRYPTOJS)
            let originalText = bytes.toString(CryptoJS.enc.Utf8)
            return originalText;
        }
    }
}
export const getUserDataInfo = () => {
    if (localStorage) {
        let userData = localStorage.getItem(process.env.VUE_APP_LS_USERDATA)
        if (userData) {
            let bytes = CryptoJS.AES.decrypt(userData, process.env.VUE_APP_CRYPTOJS)
            let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            let user = { "uid": originalText.uid, "email": originalText.email, "emailVerified": originalText.emailVerified, "displayName": originalText.displayName, "isAnonymous": originalText.isAnonymous, "photoURL": originalText.photoURL }
            return user;
        }
    }
}
export const getUID = () => {
    if (localStorage) {
        let userData = localStorage.getItem(process.env.VUE_APP_LS_USERDATA)
        if (userData) {
            let bytes = CryptoJS.AES.decrypt(userData, process.env.VUE_APP_CRYPTOJS)
            let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            //let user = { "uid": originalText.uid, "email": originalText.email, "emailVerified": originalText.emailVerified, "displayName": originalText.displayName, "isAnonymous": originalText.isAnonymous, "photoURL": originalText.photoURL }
            return originalText.uid
        }
    }

}

export const encrytData = (data) => {
    let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_KEY);
    let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_IV);
    let chiperData = CryptoJS.AES.encrypt(data, key, { iv: iv })
    let originalText = chiperData.toString();
    return originalText;
}
export const decryptData = (data) => {
    let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_KEY);
    let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTOJS_IV);
    let chiperData = CryptoJS.AES.decrypt(data, key, { iv: iv })
    let originalText = chiperData.toString(CryptoJS.enc.Utf8);
    return originalText;
}



