<template>
  <v-app>
    <v-row>
      <v-col cols="12">
        <SpookLoaderVue></SpookLoaderVue>
      </v-col>
    </v-row>

    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen" style="margin-top: 15px"></vertical-nav-menu>
    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <v-col cols="2" class="pt-4 mt-8 hidden-sm-and-down" id="country">
            <v-select
              prepend-inner-icon="mdi-earth"
              outlined
              label="Country"
              v-model="currentCountry"
              :items="countries"
              item-value="code"
              item-text="name"
              @change="countrySelected()"
            >
              <template v-slot:item="slotProps">
                <i :class="['mr-2', 'em', slotProps.item.flag]"></i>
                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>
          <v-col class="pt-4 mt-8" id="devices">
            <v-select
              v-model="currentSelect"
              v-bind:items="devices"
              label="Devices"
              outlined
              prepend-inner-icon="mdi-view-dashboard"
              item-text="manufacturer"
              item-value="mobileIMEI"
              @change="deviceSelected()"
            ></v-select>
          </v-col>
          <theme-switcher></theme-switcher>
          <v-btn icon small class="ms-3">
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2022 - 2023
            <a href="https://www.kumawat.co.in" class="text-decoration-none" target="_blank">Kumawat Team</a></span
          >
          <span class="d-sm-inline d-none">
           
             <a href="https://forumkumawat.blogspot.com/" target="_blank" class="me-6 text--secondary text-decoration-none"
              >Forum & Help</a
            >
   
            <a href="https://www.kumawat.co.in" target="_blank" class="me-6 text--secondary text-decoration-none"
              >Blog</a
            >
   
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>
<style scoped>
#country > .v-text-field {
  width: 150px;
}
#devices > .v-text-field {
  width: 140px;
}
</style>
<script>
import { collection, addDoc, setDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore'
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import { get, child } from 'firebase/database'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import { dbRef } from '../../firebaseConfig'

import {
  setUserData,
  getUserData,
  getUserDataInfo,
  getUID,
  getCurrentDeviceSelected,
  setCurrentDeviceSelected,
  decryptData,
} from '../../localStorage'
import SpookLoaderVue from '@/views/pages/SpookLoader.vue'
export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    SpookLoaderVue,
  },
  data() {
    return {
      devices: [],
      currentSelect: null,
      currentCountry: null,
      setLocale: null,
    }
  },
  mounted: function () {
    this.getListOfDevices()
    this.getConfig()
  },
  methods: {
    deviceSelected(e) {
      console.log(this.currentSelect)
      setCurrentDeviceSelected(this.currentSelect)
      console.log(getCurrentDeviceSelected())
    },
    countrySelected(e) {
      console.log(this.currentCountry)
    },
    check() {
      console.log(this.devices)
    },
    getListOfDevices() {
      const self = this
      let uid = getUID()
      console.log(uid)
      const docRef = doc(dbRef, 'mobileConfig', uid)
      getDoc(docRef)
        .then(res => {
          console.log(res.data())
          let k = res.data()

          Object.values(k).map(i => {
            let change = i
            change.manufacturer = i.manufacturer + '-' + i.model
            this.devices.push(change)
          })
          if (this.devices.length > 0) {
            this.currentSelect = this.devices[1]
            setCurrentDeviceSelected(this.currentSelect.mobileIMEI)
          }

          console.log(this.devices)
        })
        .catch(error => {
          // this.swal(false, error.message)
        })
    },
    async getConfig() {
      const self = this
      let uid = getUID()
      console.log(uid)

      const myDoc = doc(dbRef, 'enc', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          console.log(res.data())
          let kk = res.data()

          //console.log(decryptData(res.data().key))
          //           updateDoc(myDoc, {
          //   name: "Los Angeles",
          //   state: "CA",
          //   country: "USA"
          // }).then(ress=>{
          //  console.log("Hello worldd sdasdasdasd"+ress);
          // }).catch(er=>{
          //   console.log("Hello worlddd"+er);
          // });
        })
        .catch(error => {
          // this.swal(false, error.message)
          console.log(error)
        })
      //       const docRef = doc(dbRef, 'enc', uid);
      //      let my="enc/"+uid+"/"+uid+"-"+getCurrentDeviceSelected();
      // console.log(my);
      //    //   let kkk=;
      // const subColRef = collection(dbRef, "enc/"+uid,  getCurrentDeviceSelected())
      // db.
      //    subColRef
      //         .doc(uid+"-"+getCurrentDeviceSelected())
      //         .set({"2":"hello world"})
      //         .then(function () {
      //             console.log('Document Added ');
      //         })
      //         .catch(function (error) {
      //             console.error('Error adding document: ', error);
      //         });
      // //const subColRef = collection(dbRef,"enc","t08tjkzVHwQeQGSIsSXR087fw5c2/a4920e461793755b/t08tjkzVHwQeQGSIsSXR087fw5c2-a4920e461793755b");

      // //const qSnap = getDocs(subColRef)
      // //console.log(qSnap.docs.map(d => ({id: d.id, ...d.data()})))
      // const querySnapshot = await getDocs(subColRef);
      // querySnapshot.forEach((doc) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      // });
      //       getDocs(subColRef)
      //         .then(res => {
      //           console.log(res);
      //           var kns=res;
      //           console.log(kns);
      //           console.log(res.data())
      //           var k = res.data()

      //           console.log(k);
      //         })
      //         .catch(error => {
      //           console.log(error);
      //           // this.swal(false, error.message)
      //         })
    },
  },
  setup() {
    const isDrawerOpen = ref(null)
    // devices= ref([]);
    return {
      isDrawerOpen,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
      select: null,
      countries: [
        {
          name: 'Deutsch',
          flag: 'em-de',
          code: 'de',
        },
        {
          name: 'español',
          flag: 'em-es',
          code: 'es',
        },
        {
          name: 'India',
          flag: 'em-flag-in',
          code: 'en',
        },
        {
          name: 'USA',
          flag: 'em-us',
          code: 'en',
        },
        {
          name: 'français',
          flag: 'em-flag-fr',
          code: 'fr',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
