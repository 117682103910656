import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  user: null,
  lists: null,
  state: {
    user: '',
    lists: '',
    loader: false
  },
  mutations: {
    change(state, user) {
      state.user = user
    },
    LOADER(state, payload) {
      setTimeout(() => {
        state.loader = payload;
      }, 1000);

    },
  },
  getters: {
    user: state => state.user,
    lists: state => state.lists
  },
  actions: {},
  modules: {},
})
