import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import i18n from './i18n'
import VueCryptojs from 'vue-cryptojs'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.use(require('vue-moment'));
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueSweetalert2);
Vue.use(VueCryptojs);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueCryptojs,
  render: h => h(App),
}).$mount('#app')
