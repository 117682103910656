<template>
  <v-progress-linear indeterminate v-show="$store.state.loader"></v-progress-linear>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean },
  },
}
</script>

<style>
/* styles go here... duh! ;) */
</style>