import { Role } from '@/helper/role'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/pages/LandingPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import('@/views/dashboard/Plans.vue'),
    beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX  BASIC PLAN XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  {
    path: '/map', name: 'map', component: () => import('@/views/pages/spook/Map.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/flashlight', name: 'flashlight', component: () => import('@/views/pages/spook/FlashLight.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/music', name: 'music', component: () => import('@/views/pages/spook/Music.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/vibration', name: 'vibration', component: () => import('@/views/pages/spook/Vibration.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/brightness', name: 'brightness', component: () => import('@/views/pages/spook/Brightness.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/camera', name: 'camera', component: () => import('@/views/pages/spook/Brightness.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/bluetooth', name: 'bluetooth', component: () => import('@/views/pages/spook/Bluetooth.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/sound', name: 'sound', component: () => import('@/views/pages/spook/Sound.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/hotspot', name: 'hotspot', component: () => import('@/views/pages/spook/Brightness.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/screenshot', name: 'screenshot', component: () => import('@/views/pages/spook/Snapshot.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/turbo-search', name: 'turbo-search', component: () => import('@/views/pages/spook/TurboSearch.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/setting', name: 'setting', component: () => import('@/views/pages/spook/Brightness.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  {
    path: '/help', name: 'help', component: () => import('@/views/pages/spook/Brightness.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Basic, Role.Gold, Role.Platinum,] }
  },
  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX  GOLD PLAN XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  {
    path: '/contact-list', name: 'contact-list', component: () => import('@/views/pages/spook/ContactList.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Gold, Role.Platinum] }
  },
  {
    path: '/on-call', name: 'on-call', component: () => import('@/views/pages/spook/ContactList.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Gold, Role.Platinum] }
  },
  {
    path: '/sms', name: 'sms', component: () => import('@/views/pages/spook/Sms.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Gold, Role.Platinum] }
  },
  {
    path: '/call-logs', name: 'call-logs', component: () => import('@/views/pages/spook/CallLogs.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Gold, Role.Platinum] }
  },
  {
    path: '/install-app', name: 'install-app', component: () => import('@/views/pages/spook/Installapp.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Gold, Role.Platinum] }
  },

  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX  PLATINUM PLAN XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  {
    path: '/whatsapp', name: 'whatsapp', component: () => import('@/views/pages/spook/Whatsapp.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Platinum] }
  },
  {
    path: '/instagram', name: 'instagram', component: () => import('@/views/pages/spook/Instagram.vue'), beforeEnter: requireAuth,
    meta: { authorize: [Role.Platinum] }
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/pages/forgot',
    name: 'pages-forgot-password',
    component: () => import('@/views/pages/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})


router.beforeResolve((to, from, next) => {
  if (to.name) {
    console.log("beforeResolve");
    store.commit('LOADER', true);
  }
  next()
})
router.afterEach((to, from) => {
  console.log("afterEach");
  setTimeout(() => {
    store.commit('LOADER', false);
  }, 1500);

})
function requireAuth(to, from, next) {
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxx");
  // if (!store.state.user) {
  //   next('pages/login');
  // } else {
  //   next();
  // }


  const { authorize } = to.meta;
  const currentUser = store.state.user;

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/pages/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.roles.permission)) {
      // role not authorised so redirect to home page
      return next({ path: '/plans' });
    }
  }

  next();


}

export default router
